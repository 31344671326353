<template>
  <Layout>
    <v-card class="elevation-0" outlined>
      <v-card-title>
        <span class="text-h5">Add new shareholder</span>
        <v-spacer grow></v-spacer>
        <v-radio-group v-if="false" mandatory row>
          <v-radio color="red darken-4" label="Active" value="Active"></v-radio>
          <v-radio color="red darken-4" label="Abandoned" value="Abandoned"></v-radio>
        </v-radio-group>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pa-0 fill-height">
          <v-row justify="center" align="center">
            <v-col cols="12" class="py-0">
              <v-stepper v-model="stepper" vertical class="bg elevation-0">
                <v-stepper-step
                  :complete="stepper > 1"
                  step="1"
                  color="red darken-4"
                >Select client type:</v-stepper-step>
                <v-stepper-content step="1">
                  <v-container fluid>
                    <v-row justify="center" align="center">
                      <v-col cols="2">
                        <v-btn
                          outlined
                          color="indigo"
                          x-large
                          @click="onTypeChosen(clientTypes.INDIVIDUAL)"
                        >
                          <v-icon>mdi-account</v-icon>Individual
                        </v-btn>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          outlined
                          color="teal"
                          x-large
                          @click="onTypeChosen(clientTypes.CORPORATE)"
                        >
                          <v-icon>mdi-office-building</v-icon>Corporate
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>
                <v-stepper-step
                  :complete="stepper > 2"
                  step="2"
                  color="red darken-4"
                >{{ secondStepText }}</v-stepper-step>
                <v-stepper-content step="2">
                  <component :is="secondStep" />
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-bottom-navigation fixed>
        <v-btn value="cancel" @click="resetAll">
          <span>Cancel</span>
          <v-icon color="red darken-4">mdi-delete</v-icon>
        </v-btn>
        <v-btn value="save" :disabled="saveDisabled" @click="save">
          <span>Save New Shareholder</span>
          <v-icon color="green darken-4">mdi-content-save</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-card>
  </Layout>
</template>
<script>
import Layout from '@layouts/main'
import ShareholderIndividualStep from '@components/Shared/ShareholderIndividualStep.vue'
import ShareholderCorporateStep from '@components/Shared/ShareholderCorporateStep.vue'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'

export default {
  name: 'NewShareholder',
  components: {
    ShareholderIndividualStep,
    ShareholderCorporateStep,
    Layout,
  },
  data() {
    return {
      stepper: 1,
      clientType: '',
      clientTypes: {
        INDIVIDUAL: 'individual',
        CORPORATE: 'corporate',
      },
      loading: false,
      shareholders: [],
    }
  },
  computed: {
    individual() {
      return this.clientType === this.clientTypes.INDIVIDUAL
    },
    corporate() {
      return this.clientType === this.clientTypes.CORPORATE
    },
    secondStepText() {
      return 'Shareholder informations'
    },
    secondStep() {
      return this.individual
        ? 'ShareholderIndividualStep'
        : 'ShareholderCorporateStep'
    },
    saveDisabled() {
      return this.stepper !== 2
    },
  },
  watch: {
    stepper(newValue) {
      this.$emit('onStepperLastStep', this.saveDisabled)
    },
  },
  beforeDestroy() {
    EventBus.$off(EventBusEvents.ON_STEP_CHANGE, this.onStepChange, this)
    EventBus.$off(EventBusEvents.RESET_NEW_CLIENT, this.reset, this)
  },
  mounted() {
    EventBus.$on(EventBusEvents.ON_STEP_CHANGE, this.onStepChange, this)
    EventBus.$on(EventBusEvents.RESET_NEW_CLIENT, this.reset, this)
  },
  methods: {
    onTypeChosen(type) {
      this.clientType = type
      this.onStepChange('next')
    },
    resetAll() {
      this.$router.push({ name: 'shareholders' })
    },
    save() {
      EventBus.$emit(EventBusEvents.ON_SAVE_CLICK_NEW_SHAREHOLDER, {}, this)
    },
    onStepChange(step) {
      if (typeof step === 'number') {
        this.stepper = step
        return
      }
      if (step === 'next') {
        this.stepper = (this.stepper !== 2 && this.stepper + 1) || 2
        return
      }

      if (step === 'back') {
        this.stepper = (this.stepper !== 0 && this.stepper - 1) || 0
      }
    },
  },
}
</script>

<style>
.bg {
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.fixed--bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
</style>
